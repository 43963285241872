<template>
    <component :is="buttonComponent" class="button-primary" type="button" :class="buttonClasses" :disabled="buttonDisabled" :to="buttonRoute" @click="buttonClick(buttonClickParams)">
        {{ buttonText }}
    </component>
</template>

<script>
export default {
    name: 'ButtonPrimary',
    props: {
        buttonColor: {
            required: false,
            type: String,
            default: 'bg-amber-700 text-white hover:bg-amber-600 focus-visible:outline-amber-700',
        },
        buttonDisabled: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
        buttonRoute: {
            required: false,
            type: [String, Object],
            default: null,
        },
        buttonText: {
            required: false,
            type: String,
            default: null,
        },
        buttonClick: {
            required: false,
            type: Function,
            default: () => {},
        },
        buttonClickParams: {
            required: false,
            type: [String, Object, Array],
            default: null,
        },
    },
    computed: {
        buttonComponent () {
            return this.buttonRoute ? 'router-link' : 'button';
        },
        buttonClasses () {
            const cls = {};
            cls['inline-block'] = true;
            cls['px-4 py-3'] = true;
            cls['rounded-md shadow-sm'] = true;
            cls['focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'] = true;
            cls['text-lg font-bold'] = true;
            cls[this.buttonColor] = true;
            cls['disabled:bg-zinc-500'] = !!this.buttonDisabled;
            return cls;
        },
    },
};
</script>
