<template>
    <div class="article-home">
        <p class="font-bold">
            Recruiting candidates for the perfect job since 1989.
        </p>
        <p>
            Freeway Recruitment is a leading independent recruitment company specialising in the placement of temporary
            and permanent staff in the driving, industrial, engineering and commercial sectors.
        </p>
        <p>
            Above all else, we have unbeatable relationships with the staff we provide and the clients we support - that
            sets us apart from our competition.
        </p>
        <p>
            <em>This is by far the main reason we have been supplying the industry for over quarter of a century.</em>
        </p>
        <p class="font-bold">
            Driving Agency Leeds - Driving Agency Manchester
        </p>
        <p>
            Many people know us for our strength providing driving jobs; and this is where the company grew from, right
            to where we are today. Over the decades we have provided thousands of driving jobs in Leeds and Manchester,
            and have always been known as the go to specialist driver recruitment agency in Leeds and Manchester.
            Logistics and fleet operations is our business.
        </p>
        <p>
            If you are looking for HGV drivers, or are a driver yourself and want new opportunities, contact us today.
        </p>
        <p class="font-bold">
            The North's Leading Agency - Yorkshire, Lancashire, Northumberland, Merseyside, Cheshire
        </p>
        <p>
            In fact, our operations cover the entire country, with a particular focus on the North. We operate all
            across Yorkshire, Greater Manchester, Lancashire, Northumberland, Merseyside and Cheshire, with excellent
            coverage in areas such as Huddersfield, Bradford, Wakefield, Liverpool, Warrington, Wigan, Bolton,
            Blackburn, St Helens and many more. More clients and staff are coming on board with us every day, from
            locations across the country.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleHome',
};
</script>
