<template>
    <div class="article-clients">
        <p>
            Freeway Recruitment operate across the North Of England from our two key branches in Leeds and Manchester.
            This is the base for our strategic coverage of both Yorkshire and Lancashire; allowing us to accurately
            recruit across a wide area radius for many of our customers who have both local, regional and national
            facilities.
        </p>
        <p>
            Talent Acquisition is at the top of our list; both within Freeway and for the staff that work on behalf of
            our clients. We acquire top talent that impacts our customers operation by ensuring we attract the best
            personnel on the market. To do that our team have a modern, flexible and advanced working environment, with
            access to all the tools required to service your needs.
        </p>
        <content-subheading heading="What Our Customers Have to Say" marginBottom="mb-6"></content-subheading>
        <p>
            “Having worked alongside Freeway Recruitment for a number of years now, our business fluctuates rapidly in
            terms of manpower needs. This need is on many occasions a very short term request. Wayne and his team never
            let me down, they are professional and efficient at all times. The quality of people Freeway send to us are
            excellent bearing in mind the amount of people we request daily. We are a 'need specific' business model and
            they never fail to send us the correct type of individual to service our commitment to our customers. I look
            forward to working the Freeway team in the future. They give us the opportunity to be the best at what we do
            and long may that continue.” <strong>Depot Manager, Retail Home Delivery Specialist, Lancashire</strong>
        </p>
        <p>
            “We have used Freeway Driving since 1997 and we never seem to be amazed on the high quality of professional
            drivers that they supply. With competitive rates and good moral I see freeway having a big future ahead of
            them.” <strong>Transport Manager, Bed Manufacturer, West Yorkshire</strong>
        </p>
        <p>
            “We have used Freeway for over 5 years now. I have found every one of their drivers punctual, polite and
            experienced HGV drivers. All tasks given to drivers have always been done in an efficient manner. Freeway
            have always managed to fill my last minute driver gaps and now I don’t need to worry what sort of driver
            will be turning up like with some agencies.” <strong>Transport Manager, Glass Distribution Specialist, West
            Yorkshire</strong>
        </p>
        <p>
            “We have been using Freeway Recruitment for many years. Their service is ALWAYS exceptional and their class
            of drivers and staff are much better than any other agency. In our industry, we have to react very quickly
            to our customers’ needs and Freeway have always understood and managed this process exceptionally well. They
            have managed to supply drivers at very short notice for us and nothing is ever too much trouble for them.”
            <strong>Northern Operations Manager, North, National Retail & Home Delivery specialist, West Yorkshire</strong>
        </p>
        <p>
            “We have worked with Freeway for a few years and have found them to be excellent, not only in filling urgent
            drivers but in the quality of the drivers supplied. The team at Freeway are excellent, friendly approachable
            and understand the needs of our business. We look forward to a long partnership with them!” <strong>Logistics
            Manager North, National Retail Specialist, Yorkshire</strong>
        </p>
        <p>
            “I have been using Freeway for a number of years now; basically they understand our needs due to the trade
            we are in. Their attention to detail is second to none, if they have a driver they feel is not suitable or
            does not have the experience to deliver our products they will refrain from sending themin, a lot of other
            companies do by promising this and that and then we are left to pick up the pieces. I have always had a
            cracking relationship with the team at Freeway and hope to continue this relationship for many years.
            Freeway are number one in my eyes for driver recruitment. Thank you all very much!” <strong>Transport
            Manager, Packaging Distribution Specialist, West Yorkshire</strong>
        </p>
        <p>
            “We have chosen to use Freeway Driving as our number one driver supplier for over 10 years now and would
            like to say thank you for the first-class service they have always provided us with. Not only have they
            supplied me with quality drivers that are as good as my own recruited team, they have always guaranteed me
            the drivers that have worked for us before, that have the exact skills and personality fit I need, more
            often than not at extremely short notice. The close working relationship I have with the Directors has also
            meant that this standard has never slipped. I would recommend Freeway’s service to any company!’’ <strong>Transport
            Manager, Glass Manufacturer, Yorkshire</strong>
        </p>
        <p>
            “I have been using Freeway for my agency driver cover for several years. In today’s climate, quality of
            driver and competitive costing has to be a given. In order to develop and maintain any business there must
            be a competitive advantage. With Wayne and his team at Freeway this advantage is the consistently high
            quality of service. As a customer, I am always made to feel that I am Freeway’s number 1 priority. My calls
            are answered any time day or night and response is impressively quick. This level of service and honesty
            allows me to manage my business effectively. It is the reason that I perceive Freeway as being a trusted
            extension of my Team rather than just another supplier.” <strong>Logistics Manager, Packaging Distribution
            Specialist, West Yorkshire</strong>
        </p>
        <p>
            “We have worked alongside Freeway recruitment for some years now and have forged a great relationship with
            both the company and drivers supplied. The Freeway team have always made us feel we are their only customer
            and never fail to impress me with their work ethic and attitude. I have no need to worry about the quality
            of drivers supplied to us are they have all been of an excellent standard. I have been contacted by so many
            different agencies over the last few years but have never had the need to change freeway as a supplier and
            don’t intend to change anything in the future” <strong>Transport Manager, General Haulage Specialist, West Yorkshire</strong>
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleClients',
};
</script>
