<template>
    <div class="article-contact">
        <p>
            Alternatively, send over your details and we'll be in touch.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleContact',
};
</script>
