<template>
    <div class="article-about">
        <content-subheading heading="Evolution" marginBottom="mb-6"></content-subheading>
        <p>
            Freeway Recruitment is a leading north England based independent recruitment company specialising in the
            Placement of temporary and permanent staff. Since our birth in 1989, where we started out as a specialist
            HGV driver supplier, we have evolved into a business whose brand is for known for it’s ability to find and
            place staff, day in day out, year on year, and through any market/economic condition. The company has seen
            considerable growth every year since owner and director Wayne Clark joined in 2008. This now includes the
            expansion and growth across the M62 corridor and into and throughout Lancashire in 2013.
        </p>
        <content-subheading heading="Quality" marginBottom="mb-6"></content-subheading>
        <p>
            Since that point Freeway have specifically placed ‘ consistent quality control’ and ‘never ending
            improvement’ at the forefront of our day to day operation. By focusing our efforts on working with a
            dedicated customer base rather than anyone and everyone, we are able to ‘properly’ manage and control front
            end recruitment. Accepting only candidates with the best skills, behaviours and attitudes, and therefore
            adding visible quality and depth to our labour pool.
        </p>
        <content-subheading heading="Focus" marginBottom="mb-6"></content-subheading>
        <p>
            Our focus towards quality over quantity means that our internal processes don't have to be sacrificed at the
            expense of being over stretched. The case for many recruiters nowadays is that they take too much business,
            stretching capacity, and then, when peak periods increase, quality levels inevitably fall! Many agencies
            knowingly break SLA agreements or internal compliance supplying inadequately skilled staff to customers when
            pressured. This results in raised on/off accidents, H&S breaches, staff not turning up leading to low or
            failed production or warehouse targets, missed distribution drops, poor customer services and lowered driver
            performance.
        </p>
        <content-subheading heading="Relationships" marginBottom="mb-6"></content-subheading>
        <p>
            Above all else and underlying everything it is the unbeatable relationships we have with the staff we
            provide and the clients we support that sets us apart from our competition. It is by far the main reason we
            have been supplying the industry since 1989.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAbout',
};
</script>
