
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as about_45usjHYDYdIpYFMeta } from "/codebuild/output/src877752701/src/nuxt/pages/about-us.vue?macro=true";
import { default as clientsprGMLUX5U7Meta } from "/codebuild/output/src877752701/src/nuxt/pages/clients.vue?macro=true";
import { default as contactplf7DqWFHIMeta } from "/codebuild/output/src877752701/src/nuxt/pages/contact.vue?macro=true";
import { default as index2C2y7uAY68Meta } from "/codebuild/output/src877752701/src/nuxt/pages/index.vue?macro=true";
import { default as _91jobSlug_93r0I0s1aDeaMeta } from "/codebuild/output/src877752701/src/nuxt/pages/jobs/[jobSlug].vue?macro=true";
import { default as indexRkxZ011JEuMeta } from "/codebuild/output/src877752701/src/nuxt/pages/jobs/index.vue?macro=true";
import { default as terms0RNLdbbnFVMeta } from "/codebuild/output/src877752701/src/nuxt/pages/terms.vue?macro=true";
export default [
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src877752701/src/nuxt/pages/about-us.vue")
  },
  {
    name: "clients",
    path: "/clients",
    component: () => import("/codebuild/output/src877752701/src/nuxt/pages/clients.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/codebuild/output/src877752701/src/nuxt/pages/contact.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src877752701/src/nuxt/pages/index.vue")
  },
  {
    name: "jobs-jobSlug",
    path: "/jobs/:jobSlug()",
    component: () => import("/codebuild/output/src877752701/src/nuxt/pages/jobs/[jobSlug].vue")
  },
  {
    name: "jobs",
    path: "/jobs",
    component: () => import("/codebuild/output/src877752701/src/nuxt/pages/jobs/index.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/codebuild/output/src877752701/src/nuxt/pages/terms.vue")
  }
]